/* ToolsDisplay.css */
.tools-display {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  
    gap: 2rem;
    padding: 2rem;
    background-color: #0a0f1a;
    min-height: 100vh;
    
  }
  