.icon-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.fas.fa-gift {
  font-size: 4em;
  color: #8800ff;
  margin-top:-15px;
  margin-bottom: -10px;
}

.shaking {
  animation: shake-rotate 1.5s infinite;
}

@keyframes shake-rotate {
  0%, 100% {
    transform: rotate(0deg);  /* Initial position */
  }
  25% {
    transform: rotate(-15deg); /* Rotate 15 degrees to the left */
  }
  50% {
    transform: rotate(15deg);  /* Rotate 15 degrees to the right */
  }
  75% {
    transform: rotate(-15deg); /* Rotate 15 degrees to the left */
  }
}

/* Existing CSS */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #0a0f1a;
  padding: 50px;
  border-radius: 15px;
  text-align: center;
  max-width: 550px;
  height: 550px;
  width: 90%;
  color: #e0e0e0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 5px 100px rgba(136, 0, 255, 0.3);    overflow: hidden;

}

.modal-content h2 {
  margin-bottom: 20px;
  font-size: 2em;
}

.modal-content p {
  font-size: 1.2em;
  line-height: 1.6em;
  margin-bottom: 40px;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.modal-button {
  padding: 12px 0;
  font-size: 1.1em;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.3s ease;
  flex-grow: 1;
  max-width: 400px;
  width: 220px;
}

.modal-button:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .button-group {
    flex-direction: column;
  }

  .modal-button {
    width: 100%;
    margin-bottom: 10px;
  }
  .modal-content{
    font-size: 12px;
  }
  .button-group{
    gap:5px;
  }

}
