.roadmap-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px; /* Reduced padding from 20px to 10px */
    margin-top: -18%; /* Optionally, add negative margin to push it further up */
    margin-bottom: 50px;
    color: #e0e0e0;
    @media (max-width: 768px) {
      margin-top: -90%;
    }
  }
  
  .gradient-text{
    background: linear-gradient(90deg, #4ce0d2, #8800ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  
  font-weight: bold;
  }

  .timeline {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: 30px; /* Add space on the left for the line */
  }
  
  .timeline-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 20px 0;
    position: relative;
  }
  
  .timeline-content {
    display: flex;
    align-items: center;
  }
  
  .timeline-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    border: 2px solid #393965;
    margin-right: 10px;
    position: relative;
    z-index: 2; /* Ensure circles are on top of the line */
  }
  
  .timeline-circle.filled {
    background: linear-gradient(135deg, #8800ff, #a64dff);
  }
  
  .timeline-text {
    display: flex;
    flex-direction: column;
  }
  
  .timeline-label {
    font-weight: bold;
  }
  
  .timeline-description {
    font-size: 14px;
    margin-top: 5px;
    color: #e0e0e0;
  }
  
  /* The line connecting the circles */
  .timeline-line {
    position: absolute;
    left: 10px;
    top: 30px;
    width: 2px;
    height: 100%; /* Adjust the height of the line */
    background-color: #393965;
    z-index: 1; /* Behind the circles */
  }
  
  .timeline-line.filled {
    background: linear-gradient(135deg, #8800ff, #a64dff);
  }
  
  /* Ensure the last circle doesn't have a line after it */
  .timeline-item:last-child .timeline-line {
    display: none;
  }
  