body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #0a0f1a;/*linear-gradient(135deg, #0a0f1a, #30336b); /* OR #0a0f1a*/
  color: #e0e0e0;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

* {
  box-sizing: border-box;
}

button, a {
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  transform: scale(1.05);
}

button {
  border: none;
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  color: #e0e0e0;
}

section, div, button {
  border-radius: 15px;
}

/* Gradient Button Style */
button.gradient {
  background: linear-gradient(135deg, #8800ff, #a64dff);
  color: white;
}

button.gradient:hover {
  background: linear-gradient(135deg, #a64dff, #8800ff);
}

/* Responsive Styles */
@media (max-width: 768px) {
  body {
    padding: 10px;
  }
  
  h1, h2, h3 {
    font-size: 1.5em;
  }

  section {
    margin-bottom: 20px;
    padding: 10px;
  }

  button {
    width: 100%;
    padding: 10px;
  }

  .container {
    flex-direction: column;
    align-items: center;
  }
}
