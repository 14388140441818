/* Airdrop.css */

/* Ensure the container and content are centered */
.airdrop-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


/* Explanation Container */
.explanation-container {
  background-color: #1e1e1e; /* Dark solid background */
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;
  width: 100%;
  max-width: 800px; /* Limit the width of the explanation container */
}

.explanation-container p {
  font-size: 1.2rem;
  color: #e0e0e0;
  line-height: 1.6;
  margin: 0;
}


/* Progress Bar */
.progress-container {
  background-color: #333;
  border-radius: 30px;
  overflow: hidden;
  width: 100%;
  max-width: 600px; /* Limit the width of the progress bar */
  height: 40px;
  margin: 2rem 0;
  position: relative;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.progress-bar {
  background: linear-gradient(135deg, #8800ff, #a64dff);
  color: #fff;
  height: 100%;
  line-height: 40px;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 3px 10px rgba(136, 0, 255, 0.3);
  transition: width 0.4s ease-in-out;
}

.progress-bar span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  font-weight: bold;
}

/* Eligibility Check Input and Button */
.eligibility-container {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 80px;

}

.eligibility-container input {
  padding: 0.8rem 1.2rem;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.1);
  color: #e0e0e0;
  width: 280px;
  transition: background 0.3s ease;
}

.eligibility-container input:focus {
  background: rgba(255, 255, 255, 0.2);
}

.eligibility-container button {
  padding: 0.8rem 1.6rem;
  background: linear-gradient(135deg, #8800ff, #a64dff);
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease;
}
.disabled-button {
  padding: 0.8rem 1.6rem;
  background: linear-gradient(135deg, #aaaa, #aaaa);
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease;
}
.eligibility-container button:hover {
  background: linear-gradient(135deg, #a64dff, #8800ff);
  transform: scale(1.05);
  
}

/* Modal (Solid Background) */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1e1e1e;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #1e1e1e; /* Solid dark background */
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #e0e0e0;
  max-width: 500px;
  width: 100%;
}

.modal-content h2 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #fff;
}

.modal-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.modal-content button {
  padding: 0.8rem 1.6rem;
  background: linear-gradient(135deg, #8800ff, #a64dff);
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

.modal-content button:hover {
  background: linear-gradient(135deg, #a64dff, #8800ff);
  transform: scale(1.05);
}
.success-text{
  color: green;
}