/* ToolCard.css */
.tool-card {
    background-color: #1e1e1e;
    border-radius: 20px;
    transform: scale(1.05);
    box-shadow: 0 5px 30px rgba(136, 0, 255, 0.3);    overflow: hidden;
    max-width: 450px;
    min-height: 680px; /* Increased height for more spacious layout */
    margin: 1.5rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-top: 100px;
  }
  
  .tool-card:hover {
    transform: scale(1.05);
    box-shadow: 0 5px 30px rgba(136, 0, 255, 0.3);
  }
  
  .tool-image {
    width: 100%;
    height: 250px; /* Maintains focus on the image */
    object-fit: fill;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .tool-content {
    padding: 1.5rem;
  }
  
  .tool-name {
    font-size: 1.8rem; /* Emphasized tool name */
    color: #fff;
    text-align: center;
    margin-bottom: 0.8rem;
  }
  
  .tool-description {
    font-size: 1.2rem;
    color: #e0e0e0;
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }
  
  .tool-features {
    list-style-type: none;
    padding: 0;
    margin: 1rem 0;
    color: #a64dff;
  }
  
  .tool-features li {
    margin-bottom: 0.7rem;
    font-size: 1.1rem;
  }
  
  .tool-pricing {
    font-size: 1.3rem;
    font-weight: bold;
    color: #8800ff;
    text-align: center;
  }
  
  /* Separator Styling */
  .card-separator {
    border: 0;
    height: 1px;
    background: linear-gradient(90deg, #8800ff, #a64dff);
    margin: 1rem 0;
  }
  /* ComingSoonCard Overlay Styling */
.coming-soon-card {
    position: relative; /* Allows us to overlay the "Coming Soon" text */
  }
  
  .coming-soon-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 38%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; /* Ensures the overlay is on top of everything */
    border-radius: 20px;
  }
  
  .coming-soon-text {
    font-size: 2rem;
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
    background: #8800ff; /* A red background to create a "stamp" effect */
    padding: 0.5rem 1.5rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 2; /* Ensures the text is above the overlay */
  }
  
  /* Disable hover effects for Coming Soon cards */
